import React from "react"
import styled from "styled-components"

import logo1 from "../images/logo-normstahl.jpg"
// import logo2 from "../images/Icon2.svg"
// import logo3 from "../images/Icon3.svg"

const Span = styled.div`
  background: ${({ theme }) => theme.palette.lightCol};
  position: relative;
  margin: 64px 0;
  text-align: center;

  h2 {
    margin: 0px auto 48px;
    font-size: 2.2rem;
    font-weight: 700;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-bottom: 48px;
  max-width: 1060px;

  div {
    display: flex;
    flex-direction: column;
    flex: 0 1 22%;
    margin: 48px;
    font-size: 0.9rem;
    line-height: 1.5rem;

    h3 {
      margin: 12px 0;
      font-weight: 700;
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    flex-direction: column;
    align-items: center;
    div {
      max-width: 50%;
    }
  }
`

const Headline = styled.h2`
  padding-top: 48px;
  text-decoration: underline;
  text-underline-position: under;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2.2rem;
  color: ${({ theme }) => theme.palette.lightText};
`

const Logo = styled.a`
  width: 70%;
  padding: 10px;
  background: #fff;
  margin: 20px;

  :hover {
    transform: scale(1.06);
  }
`

const ImageResizer = styled.img`
  margin: 5px 0;
  width: 100%;
  padding-bottom: calc(332 / 125) %;
`

const HomeLogos = () => (
  <Span>
    <Headline> Unsere Partner </Headline>
    <Flex>
      <Logo
        href="https://normstahl.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImageResizer src={logo1} alt="Icon" />
      </Logo>
      <Logo
        href="https://normstahl.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImageResizer src={logo1} alt="Icon" />
      </Logo>
      <Logo
        href="https://normstahl.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImageResizer src={logo1} alt="Icon" />
      </Logo>
      <Logo
        href="https://normstahl.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ImageResizer src={logo1} alt="Icon" />
      </Logo>
    </Flex>
  </Span>
)

export default HomeLogos
