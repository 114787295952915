import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || "row"};
  flex: ${props => props.flex};
  justify-content: space-evenly;

  @media only screen and (max-width: ${props => props.theme.breakpoints.small}) {
    flex-direction: ${props => props.direction || "column"};
  }
`

const ImageWrapper = styled.div`
  flex: ${props => (props.side === "left" ? 1 : 1.6)};
  width: 100%;
  margin-right: ${props => (props.side === "left" ? "24px" : 0)};

  @media only screen and (max-width: ${props => props.theme.breakpoints.small}) {
    margin: auto;
    width: 90%;
  }
`

const FlexText = styled(Flex)`
  display: initial;
  align-items: center;
  justify-content: center;
  text-align: left;
  flex: 2;
  padding: 45px 64px;
  p {
    margin: 0 auto;
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.navCol};
  }
  a {

  .gatsby-image-wrapper {
    width: 200px;
    height: 75px;
    padding: 10px;
    background: #fff;
    margin: 20px 0;
  }
}
`

const Description = () => (
  <StaticQuery
    query={graphql`
      {
        normstahl: file(name: { eq: "logo-normstahl"}){
          name
          childImageSharp {
            fluid(maxWidth: 332, maxHeight: 125, quality: 100) {
              src
              aspectRatio
            }
          }
        }
        photo2: file(name: { eq: "SektionalTore-Edit" }) {
          name
          childImageSharp {
            fluid(maxWidth: 691, maxHeight: 788, cropFocus: WEST) {
              src
              aspectRatio
            }
          }
        }
        photo3: file(name: { eq: "SST_Style_Anthrazitgrau_02" }) {
          name
          childImageSharp {
            fluid(maxWidth: 670, maxHeight: 240, cropFocus: CENTER, quality: 100) {
              src
              aspectRatio
            }
          }
        }
      }
    `}
    render={props => (

        <Flex>
          <ImageWrapper side="left">
            <Img fluid={props.photo2.childImageSharp.fluid} />
          </ImageWrapper>

          <Flex direction="column" flex="1.4">
            <FlexText>
              <p>
              Die Firma Schneider Tore arbeitet seit Jahren mit der Firma
              Normstahl zusammen und verbaut Garagentore, Garagentüren
              und Torantriebe. Ein Garagentor von Normstahl ist Qualität.
              <br />
              <br />
              Ein Partner von:
              </p>
              <a href="https://normstahl.com/" target="_blank" rel="noopener noreferrer"><Img fluid={props.normstahl.childImageSharp.fluid} /></a>
            </FlexText>
            <ImageWrapper side="right">
              <Img fluid={props.photo3.childImageSharp.fluid} />
            </ImageWrapper>
          </Flex>
        </Flex>

    )}
  />
)

export default Description
