import React, { useState } from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Formik, Form, Field, ErrorMessage } from "formik"

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: ${props => props.alignItems || ""};
  flex-direction: ${props => (props.columns ? "column" : "row")};
  background-color: ${props => props.backgroundColor};
  margin-bottom: ${props => props.marginBottom};
  width: 100%;
  padding: ${props => props.padding};
`

const Image = styled(Img)`
  width: 100%;
  padding-bottom: 44%;
`

const Input = styled(Field)`
  background-color: ${({ theme }) => theme.palette.lightGrey};
  border: 1px solid ${({ theme }) => theme.palette.lightGrey};
  margin: 3px 0px;
  height: 35px;
  border-radius: 0px;
  box-shadow: none;
  padding: 10px;
`
const CheckBox = styled.input`
  margin-right: 10px;
`
const Label = styled.label`
  font-size: 12px;
`

const Button = styled.button`
  height: 35px;
  margin-top: 3px;
  background-color: ${({ theme }) => theme.palette.navCol};
  color: ${({ theme }) => theme.palette.lightText};
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.palette.lightText};
  padding: 10px;
  @media only screen and (max-width: 950px) {
    flex-direction: column;
  }
`

const ImageContainer = styled.div`
  flex: 1;
  @media only screen and (max-width: 950px) {
    display: none;
  }
`
const FormContainer = styled.div`
  display: flex;
  padding: 20px;
  flex: 1;
  flex-direction: column;
  h1 {
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 20px;
  }

  h4 {
    margin-bottom: 20px;
  }
`
const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`

const Success = styled.div`
  background: green;
  color: #fff;
  padding: 10px;
  height: 40px;
  width: 100%;
  margin: 5px;
  text-align: center;
`

function SubscribeForm (props) {
  const [wasSuccessful, setWasSuccessful] = useState(null)
return (

  <StaticQuery
    query={graphql`
      {
        photo3: file(name: { eq: "SWT_Weiß" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 370, cropFocus: CENTER) {
              src
              aspectRatio
            }
          }
        }
        photo2: file(name: { eq: "OSD_Scene_Eurostyle D338_7004_DSC8898" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 370, cropFocus: CENTER) {
              src
              aspectRatio
            }
          }
        }
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/SWT_Weiß.png|Smart_Style_Anthrazit_02|g60 sarleinsbach trend szene1b_ohne nummernschild|eurotrend_weiß reihenhaus1|OSD_Scene_Eurostyle D338_7004_DSC8898/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 1500, maxHeight: 600, cropFocus: CENTER) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <FlexContainer backgroundColor="#000" marginBottom="50px" padding="30px">
        <Container>
          <ImageContainer>
            <Image
              fluid={props.allImageSharp.edges[2].node.fluid}
              aspectRatio={props.allImageSharp.edges[2].node.aspectRatio}
            />
          </ImageContainer>

          <Formik
        initialValues={{
          name: "",
          email: "",
          nachricht: "",
        }}
        validate={values => {
          const errors = {}
          if (!values.email) {
            errors.email = "Required"
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address"
          }
          return errors
        }}

        onSubmit={async (values, { setSubmitting }) => {
          // console.log(values)
          // return;

          const response = await fetch(
            `https://europe-west1-sb-mailer.cloudfunctions.net/submitForm/jzicNaJMqfLRRKOZnN2m`,
            {
              method: `post`,
              headers: {
                Accept: `application/json`,
                "Content-Type": `application/json`,
              },
              body: JSON.stringify({...values, recipient: 'mail@schneider-tore.de'}),
            }
          )
          const result = await response.json()
          if (result.msg === `SUCCESS`) {
            setWasSuccessful(true)
          }
          setSubmitting(false)
        }}
      >
        {({ isSubmitting }) => (
          <FormContainer>
            <StyledForm>
              <div>
                <h1>Sie haben Fragen?</h1>
                <h4>
                  Bitte senden Sie uns Ihre Fragen über das Kontaktformular. Wir
                  werden uns umgehend mit Ihnen in Verbindung setzen.
                </h4>
              </div>
              <Input name="text" placeholder="NAME" required />
              <Input name="email" placeholder="E-MAIL" required />
              <ErrorMessage name="email" component="div" />
              <Input
                name="message"
                placeholder="IHRE NACHRICHT"
                style={{ height: 105 }}
                required
              />
              <div>
                <CheckBox type="checkbox" id="terms" />
                <Label htmlFor="terms">
                  Ich stimme der
                  <a href="/datenschutz"> Datenschutzerklärung</a> zu
                </Label>
              </div>
              {wasSuccessful ? <Success>Ihre Nachricht wurde gesendet.</Success> : <Button>Senden</Button> }
            </StyledForm>
          </FormContainer>
          )}
        </Formik>
        </Container>
      </FlexContainer>
    )}
  />
)}

export default SubscribeForm
