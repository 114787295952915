import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Span = styled.div`
  background: ${({ theme }) => theme.palette.navCol};
  position: relative;
  padding: 48px;
  text-align: center;

  h2 {
    text-decoration: underline;
    text-underline-position: under;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 2.2rem;
    margin-bottom: 32px;
    color: ${({ theme }) => theme.palette.lightText};
  }

  p {
    margin: 16px auto;
    max-width: 576px;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.palette.lightText};
  }
`

const Welcome = () => (
  <Span>
    <h2>Herzlich Willkommen bei Schneider Tore.</h2>
    <p>
      Schön, dass Sie unsere Homepage besuchen. Hier finden Sie alle wichtigen
      Informationen zu unserem Unternehmen und unseren Leistungen – von A wie
      Antrieb über G wie Garagentore, -Türen und -Systeme bis hin zu Z wie
      Zargen. Natürlich beraten wir Sie nicht nur als Bauherr und Renovierer, 
      sondern auch im industriellen Sektor.
    </p>
    <p>
      Was unterscheidet Schneider Tore von anderen Anbietern? Was macht uns stark und treibt uns
      jeden Tag aufs Neue an? <Link to="/about">Erfahren Sie hier</Link> mehr
      über unsere spannende Geschichte und unser Team.
    </p>
  </Span>
)

export default Welcome
