import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Container from "@material-ui/core/Container"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "../components/carousel"
import Welcome from "../components/welcome"
import HomeLogos from "../components/home-logos"
import Description from "../components/home-description"
import ProductGrid from "../components/product-grid"
import SubscribeForm from "../components/subscribe-section"
import TestimonialsSlider from "../components/testimonials-slider"

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      {
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/Photo2.jpg|Photo3.jpg|Photo4.jpg|Photo5.jpg/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid {
                src
                aspectRatio
                originalName
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Entrematic</title>
          <meta name="description" content="Entrematic Bauelemente" />
        </Helmet>
        <SEO title="Home" keywords={[`bau`, `elemente`, `atz`]} />
        <Carousel />
        <Welcome />
        <Container>
          <Description />

          {/* <ServicesBlock /> */}

          <ProductGrid />
          {/* <HomeLogos /> */}
          <TestimonialsSlider />
          <SubscribeForm />
        </Container>
      </Layout>
    )}
  />
)

export default IndexPage
