import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const GridItem = styled.a`
  width: calc(25% - 10px);
  min-height: 350px;
  margin: 5px;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(50% - 10px);
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    width: 100%;
    margin: 5px 0px;
  }
`

const GridImage = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const ImageOverlay = styled.div`
  background: rgba(29, 141, 190, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  font-size: 0.8rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.lightText};
`

const ImageOverlayDescription = styled.p`
  display: ${props => (props.overlayOpen ? "" : "none")};
  @media only screen and (max-width: ${props => props.theme.breakpoints.medium}){
    display: initial;
  }
`

const Headline = styled.h3`
  font-weight: 600;
  padding-bottom: ${props => (props.overlayOpen ? "5px" : "")};
`

const Image = styled(Img)`
  height: ${props => (props.height ? props.height : "")};
  width: ${props => (props.width ? props.width : "")};

  @media only screen and (max-width: ${props => props.theme.breakpoints.xl}) {
        width: initial;
      }
    }
`

function ProductGridItem(props) {
  const [overlayOpen, toggleOverlay] = useState(false)
  const { fluid, aspectRatio, headline, text, height, width, link } = props
  return (
    <GridItem
      onMouseEnter={() => toggleOverlay(true)}
      onMouseLeave={() => toggleOverlay(false)}
      className="grid-item"
      href={link}
    >
      <GridImage>
        <Image
          fluid={fluid}
          aspectRatio={aspectRatio}
          height={height}
          width={width}
        />
        <ImageOverlay width={width}>
          <Headline overlayOpen={overlayOpen}>{headline}</Headline>
          <ImageOverlayDescription overlayOpen={overlayOpen}>
            {text}
          </ImageOverlayDescription>
        </ImageOverlay>
      </GridImage>
    </GridItem>
  )
}

export default ProductGridItem
