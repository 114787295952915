import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const styles = {
  slide: {
    position: "relative",
    color: "#fff",
  },
  slide1: {
    overflow: "hidden",
  },
  slide2: {
    overflow: "hidden",
  },
  slide3: {
    overflow: "hidden",
  },
  slide4: {
    overflow: "hidden",
  },
  slide5: {
    overflow: "hidden",
  },
}

const Wrapper = styled.div`
  margin: 0;
  @media only screen and (max-width: ${props => props.theme.breakpoints.small}) {
    padding-top: 63px;
  }
`

const Margins = styled.div`
  max-width: 1060px;
  margin: 0 auto;
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    filter: brightness(0.8);
  }
`

const SlideText = styled.div`
  position: absolute;
  top: 57.5%;
  max-width: 700px;

  h1 {
    font-size: 3.5rem;
    font-weight: 900;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.2rem;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);

    a {
      font-size: 0.85rem;
      background: ${props => props.theme.palette.mainBlue};
      padding: 10px;
      border-radius: 10px;
      color: ${props => props.theme.palette.lightText};
      text-transform: uppercase;
      text-decoration: none;
      text-shadow: none;
    }
  }



  @media only screen and (max-width: 1115px) {
    top: 22%;
    margin-left: 32px;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    position: relative;
    color: black;
    margin-top: 18px;
    margin-bottom: 32px;

    h1 {
      text-shadow: none;
    }

    p {
      text-shadow: none;
    }
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.smallest}) {
    text-align: center;
    margin: 32px;

    h1 {
      font-size: 2.2rem;
    }

    p {
      font-size: 0.6rem;
    }
  }
`

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const Carousel = () => (
  <StaticQuery
    query={graphql`
      {
        deckensektionaltore: file(name: { eq: "DST_Superior_Style_Weiss_02" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1500, maxHeight: 600, quality: 100, cropFocus: CENTER) {
              src
              aspectRatio
            }
          }
        }
        seitensektionaltore: file(name: { eq: "SektionalTore-Edit" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1500, maxHeight: 600, quality: 100, cropFocus: CENTER) {
              src
              aspectRatio
            }
          }
        }
        industrietore: file(name: { eq: "ID_scene_Verglasung_242_9006_Nr3885" }) {
          name
          childImageSharp {
            fluid(maxWidth: 1500, maxHeight: 600, quality: 100, cropFocus: CENTER) {
              src
              aspectRatio
            }
          }
        }
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/ID_scene_Verglasung_242_9006_Nr3885.jpg|SektionalTore-Edit|SST_Style_Anthrazitgrau_01|SST_Style_Anthrazitgrau_02/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 1500, maxHeight: 600, cropFocus: CENTER) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Wrapper>
        <AutoPlaySwipeableViews interval="6000">
          <div style={Object.assign({}, styles.slide, styles.slide3)}>
            <ImageWrapper>
              <Img fluid={props.deckensektionaltore.childImageSharp.fluid} />
            </ImageWrapper>
            <Margins>
            <SlideText>
              <h1>
              Decken<wbr/>sektionaltore
              </h1>
              <p>
              Normstahl Deckensektionaltore zeichnen sich durch
              ihre einzigartige Kombination aus Ästhetik und Funktion aus.
                <br />
                <br />
                <a href="/deckensektionaltore">MEHR ERFAHREN</a>
              </p>
            </SlideText>
            </Margins>
          </div>

          <div style={Object.assign({}, styles.slide, styles.slide1)}>
            <ImageWrapper>
              <Img fluid={props.industrietore.childImageSharp.fluid} />
            </ImageWrapper>
            <Margins>
            <SlideText>
              <h1>
              Industrie<wbr/>tore
              </h1>
              <p>
              Normstahl Hallentore sind die souveräne Lösung
              für den Einsatz in Industrie und Gewerbe.
                <br />
                <br />
                <a href="/industrietore">MEHR ERFAHREN</a>
              </p>
            </SlideText>
            </Margins>
          </div>

          <div style={Object.assign({}, styles.slide, styles.slide2)}>
            <ImageWrapper>
              <Img fluid={props.seitensektionaltore.childImageSharp.fluid} />
            </ImageWrapper>
            <Margins>
            <SlideText>
              <h1>
              Seiten<wbr/>sektionaltore
              </h1>
              <p>
              Bei diesem Torsystem rollen die Paneele
              seitwärts entlang der Wand in die Garage hinein.
                <br />
                <br />
                <a href="/seitensektionaltore">MEHR ERFAHREN</a>
              </p>
            </SlideText>
            </Margins>
          </div>
          {/*
          <div style={Object.assign({}, styles.slide, styles.slide4)}>
            <ImageWrapper>
              <Img fluid={props.allImageSharp.edges[3].node.fluid} />
            </ImageWrapper>
            <Margins>
              <SlideText>
                <h1>
                  WILLKOMMEN BEI
                  <br />
                  SCHNEIDER-TORE
                </h1>
                <p>
                  Die Firma Schneider Tore ist in der 2. <br />
                  Generation Ihr Partner für Garagentor, <br />
                  Garagentüre und Tor-Antrieb.
                  <br />
                  <br />
                  <a href="/seitensektionaltore">MEHR ERFAHREN</a>
                </p>
              </SlideText>

            </Margins>
          </div>

          <div style={Object.assign({}, styles.slide, styles.slide5)}>
            <ImageWrapper>
              <Img fluid={props.allImageSharp.edges[4].node.fluid} />
            </ImageWrapper>
            <Margins>
              <SlideText>
                <h1>Lorem ipsum dolor ist.</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum sit amet ipsum dolor sit ipsum dolor sit tempus
                  augue.
                </p>
              </SlideText>
            </Margins>
          </div>
          */}
        </AutoPlaySwipeableViews>
      </Wrapper>
    )}
  />
)

Carousel.propTypes = {
  images: PropTypes.string,
}

Carousel.defaultProps = {
  images: ``,
}

export default Carousel
