import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import ProductGridItem from "./product-grid-item"

const FlexGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 48px 0px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    margin: auto auto 48px;
    width: 90%;
  }

  .grid-text {
    background: ${props =>
      props.theme.palette[props.bgCol] || props.theme.palette.navCol};
  }
`

const GridItem = styled.div`
  width: calc(25% - 10px);
  min-height: 350px;
  margin: 5px;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(50% - 10px);
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    width: 100%;
    margin: 5px 0px;
  }
`

const GridText = styled.div`
  padding: 48px 32px;
  width: min-content;
  margin: auto;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: ${props =>
    props.theme.palette[props.color] || props.theme.palette.lightText};

  h2 {
    margin-bottom: 14px;
    font-size: 2.3rem;
    font-weight: 800;
    line-height: normal;
  }

  p {
    font-weight: 500;
  }
`

const HR = styled.hr`
  width: 50%;

  border: 1px solid
    ${props =>
      props.theme.palette[props.color] || props.theme.palette.lightText};
`

function ProductGrid() {
  return (
    <StaticQuery
      query={graphql`
        {

          tore: file(name: { eq: "SST_Style_Anthrazitgrau_01" }){
            name
            childImageSharp {
              fluid(maxWidth: 350, maxHeight: 525, cropFocus: CENTER) {
                src
                aspectRatio
                originalName
              }
            }
          }
          antriebe: file(name: { eq: "torantrieb-magic-innen-g60-0211" }){
            name
            childImageSharp {
              fluid(maxWidth: 350, maxHeight: 525, cropFocus: CENTER) {
                src
                aspectRatio
                originalName
              }
            }
          }
          service: file(name: { eq: "Service" }){
            name
            childImageSharp {
              fluid(maxWidth: 350, maxHeight: 525, cropFocus: CENTER) {
                src
                aspectRatio
                originalName
              }
            }
          }
        }
      `}
      render={props => (
        <FlexGrid>
          <GridItem className="grid-text">
            <GridText>
              <HR />
              <h2>PRODUKTE & SERVICE</h2>
              <p>
              Perfekter Service beginnt bei uns als Normstahl-Partner schon bei
              A wie Aufmaß und B wie Beratung. Erwarten Sie von uns keine
              08/15-Lösung, sondern eine perfekt zu Ihren individuellen
              Vorstellungen passende Tor- oder Türlösung, welche auch die
              baulichen Bedingungen und Spezifikationen vor Ort berücksichtigt.
              </p>
            </GridText>
          </GridItem>

          <ProductGridItem
            fluid={props.tore.childImageSharp.fluid}
            aspectRatio={props.tore.childImageSharp.aspectRatio}
            headline={"Tore"}
            text={`Ob Deckensektionaltore, Schwingtore oder Industrietore: Wir haben für jeden Bedarf und für jedes Business die perfekte Lösung. Mehr/Weiter`}
            link={"/products#Tore"}
          />
          <ProductGridItem
            fluid={props.service.childImageSharp.fluid}
            aspectRatio={props.service.childImageSharp.aspectRatio}
            headline={"Service"}
            text={`Perfekter Service beginnt bei uns als Normstahl-Partner schon bei A wie Aufmaß und B wie Beratung. Mehr/Weiter`}
            link={"/products#Service"}
          />
          <ProductGridItem
            fluid={props.antriebe.childImageSharp.fluid}
            aspectRatio={props.antriebe.childImageSharp.aspectRatio}
            headline={"Antriebe"}
            text={`Normstahl Magic-Garagentorantriebe ermöglichen das automatische Öffnen und Schließen Ihres Tores per Funk oder per Schlüsseltaster. Mehr/Weiter
              `}
            link={"/products#Antriebe"}
          />
        </FlexGrid>
      )}
    />
  )
}

export default ProductGrid
