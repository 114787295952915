import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import MobileStepper from "@material-ui/core/MobileStepper"
import styled from "styled-components"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import React from "react"
import SwipeableViews from "react-swipeable-views"

const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.mainBlue};
  padding: 30px;
  color: white;

  p {
    margin: 10px;
  }
`

const StyledMobileStepper = styled(MobileStepper)`
  background: ${({ theme }) => theme.palette.mainBlue};
  display: ${props => props.hidden};

  .MuiMobileStepper-dot {
    background-color: ${({ theme }) => theme.palette.lightText};
  }
  .MuiMobileStepper-dotActive {
    background-color: #3f51b5;
    padding: 0;
  }
  .MuiSvgIcon-root {
    fill: white;
    width: 2em;
    height: 2em;
  }
`

const StyledBox = styled(Box)`
  margin: 64px 0;
`


var testimonials = [
  {
    name: "Ronnie Eigenbrodt",
    imageURL:
      "putneyhigh.gdst.net/wp-content/uploads/2018/06/person-placeholder-male-5.jpg",
    testimonial:
      " Herr Schneider war immer zuverlässig und arbeitete perfekt und sauber. Wir würden uns diese Einstellung bei allen Handwerkern wünschen.",
  },
  // {
  //   name: "Ronnie Eigenbrodt",
  //   imageURL:
  //     "putneyhigh.gdst.net/wp-content/uploads/2018/06/person-placeholder-male-5.jpg",
  //   testimonial:
  //     " Herr Schneider war immer zuverlässig und arbeitete perfekt und sauber. Wir würden uns diese Einstellung bei allen Handwerkern wünschen.",
  // },
  // {
  //   name: "Ronnie Eigenbrodt",
  //   imageURL:
  //     "putneyhigh.gdst.net/wp-content/uploads/2018/06/person-placeholder-male-5.jpg",
  //   testimonial:
  //     " Herr Schneider war immer zuverlässig und arbeitete perfekt und sauber. Wir würden uns diese Einstellung bei allen Handwerkern wünschen.",
  // },
  // {
  //   name: "Ronnie Eigenbrodt",
  //   imageURL:
  //     "putneyhigh.gdst.net/wp-content/uploads/2018/06/person-placeholder-male-5.jpg",
  //   testimonial:
  //     " Herr Schneider war immer zuverlässig und arbeitete perfekt und sauber. Wir würden uns diese Einstellung bei allen Handwerkern wünschen.",
  // }
]

function TestimonialsSlider(props) {
  const [activeStep, setActiveStep] = React.useState(0)

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  function handleStepChange(step) {
    setActiveStep(step)
  }
  return (
    <StyledBox>
      <SwipeableViews
        enableMouseEvents
        // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        style={{
          overflow: "hidden",
        }}
        onChangeIndex={handleStepChange}
      >
        {testimonials.map((step, index) => (
          <Testimonial key={step.id} testimonial={step}>
            {/* <img src={step.imageURL}/> */}
            <p>{step.testimonial}</p>
            <p>{step.name}</p>
          </Testimonial>
        ))}
      </SwipeableViews>
      <StyledMobileStepper
        activeStep={activeStep}
        hidden={testimonials.length === 1 ? 'none' : 'flex'}
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {/* {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : ( */}
            <KeyboardArrowLeft />
            {/* )}
            Back */}
          </Button>
        }
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={testimonials && activeStep === testimonials.length - 1}
          >
            {/* Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : ( */}
            <KeyboardArrowRight />
            {/* )} */}
          </Button>
        }
        position="static"
        steps={testimonials && testimonials.length}
        variant="dots"
      />
    </StyledBox>
  )
}

export default TestimonialsSlider
